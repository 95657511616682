/* hide arrow up and down on input type number: Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* hide arrow up and down on input type number: Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

#bemoved-web {
  font-family: 'DM Sans';
  font-weight: 100;
  font-style: normal;
}

body {
  font-family: 'DM Sans';
  padding: 0;
  margin: 0;
  font-weight: 100;
  font-style: normal;
}

.orText {
  font-family: 'DM Sans', 'Courier New', Courier, monospace;
  overflow: hidden;
  text-align: center;
  color: #c2c9d1;
}

.orText:before,
.orText:after {
  background-color: #c2c9d1;
  content: '';
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.orText:before {
  right: 0.5em;
  margin-left: -50%;
}

.orText:after {
  left: 0.5em;
  margin-right: -50%;
}

/** Customer Onboarding Steps */

.layout-navbar {
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  flex-grow: 1;
}

body,
#react-joyride-portal {
  position: relative;
  /* overflow: hidden; TODO: Why are we hiding overflow?*/
  max-height: 100vh;
}

#react-joyride-step-0 .__floater.__floater__open {
  margin-top: 10px;
}

#react-joyride-step-0 .__floater__arrow span {
  top: 10px !important;
}

#react-joyride-step-1 .__floater__arrow span {
  left: 60px !important;
}

#react-joyride-step-1 .react-joyride__tooltip {
  right: 250px !important;
}

#react-joyride-step-2 .__floater__arrow span {
  left: 200px !important;
}

#react-joyride-step-2 .react-joyride__tooltip {
  right: 100px !important;
}

.__floater.__floater__open button {
  color: #232323 !important;
}

.swal2-container.swal2-center.swal2-backdrop-show {
  z-index: 99999999;
}

.swal2-cancelBtn {
  color: #000 !important;
  background: transparent !important;
  border-radius: 10px !important;
  border: 1px solid #ffdf63 !important;
  font-size: 13px !important;
  height: 50px !important;
  width: 100px !important;
}

.swal2-confirmBtn {
  color: #000 !important;
  border-radius: 10px !important;
  border: 1px solid #ffdf63 !important;
  font-size: 13px !important;
  height: 50px !important;
  width: 100px !important;
  background: #ffdf63 !important;
}

h2.fc-toolbar-title {
  display: inline;
  vertical-align: middle;
}

.fc .fc-button-primary.fc-myCustomTitle-button {
  cursor: default;
  pointer-events: none;
  background: transparent;
  border: 0;
  color: #304261;
  font-size: 28px;
  padding-right: 0;
  padding-left: 0;
}

.fc-today {
  background: #fff !important;
  border: none !important;
}

input {
  font-family: 'DM Sans';
}

::-webkit-input-placeholder {
  font-family: 'DM Sans';
  font-size: 14px;
}

::-moz-placeholder {
  font-family: 'DM Sans';
  font-size: 14px;
}

:-ms-input-placeholder {
  font-family: 'DM Sans';
  font-size: 14px;
}

::placeholder {
  font-family: 'DM Sans';
  font-size: 14px;
}

input,
textarea,
select {
  font-family: 'DM Sans';
}

fieldset > legend {
  display: none;
}

.makeStyles-mainSection-119 {
  background-color: #f5f5f5;
}

#agentFullName {
  text-transform: capitalize;
}

.monthSelector > div > fieldset {
  display: none;
  padding: 0;
  margin: 0;
}

// not sure why this is exist, keeping this incase something bad happened
// .react-multi-carousel-list li:last-child button {
//   margin-top: -15px;
// }

.react-multi-carousel-list {
  position: unset !important;
  height: 100%;

  .react-multi-carousel-track {
    height: 100%;
  }

  .react-multiple-carousel__arrow {
    position: absolute;
  }
}

p.MuiTablePagination-displayedRows {
  font-family: 'DM Sans';
}

.MuiCardHeader-avatar.css-1ssile9-MuiCardHeader-avatar {
  width: 100%;
}

.MuiCardHeader-avatar.css-1ssile9-MuiCardHeader-avatar > img {
  object-fit: fill;
}

.MuiCardHeader-avatar.css-1ssile9-MuiCardHeader-avatar > .MuiAvatar-circular {
  width: fit-content;
}

.myJobsSort > div > fieldset {
  display: none;
}

#usersDiv > div > fieldset {
  display: none;
}

.fc-prev-button.fc-button.fc-button-primary,
.fc-next-button.fc-button.fc-button-primary {
  background-color: #f5f5f5;
  color: #6ab1e1;
  border: none;
}

.fc-prev-button.fc-button.fc-button-primary:hover,
.fc-next-button.fc-button.fc-button-primary:hover {
  background-color: #f5f5f5;
  color: #6ab1e1;
  border: none;
}

.fc-timeGridWeek-button,
.fc-dayGridMonth-button,
.fc-timeGridDay-button {
  background-color: #f5f5f5 !important;
  border: none !important;
  color: #868c93 !important;
}

.fc-button-active {
  background-color: #f5f5f5 !important;
  border-radius: 0 !important;
  border: none !important;
  color: #868c93 !important;
  box-shadow: none !important;
  border-bottom: 4px solid #6ab1e1 !important;
}

.fc-timeGridWeek-button:focus,
.fc-dayGridMonth-button:focus,
.fc-timeGridDay-button:focus {
  box-shadow: none !important;
}

.fc-scrollgrid,
tbody {
  background-color: #fff;
}

.css-yw48mr-MuiPaper-root-MuiCard-root {
  width: 240px;
}

.css-1v5z4dq-MuiPaper-root-MuiCard-root {
  text-align: center;
}

.css-1v5z4dq-MuiPaper-root-MuiCard-root h6 b {
  font-size: 24px;
  font-weight: bold;
}

.css-1v5z4dq-MuiPaper-root-MuiCard-root h6 {
  font-size: 10px;
}

// This was commented because it's pointless to use divider on components under mainSection. Furthermore,
// I believe the xy number might change so hardcoding the number is not a good idea.
//.makeStyles-mainSection-25 .makeStyles-divider-24 {
//  display: none;
//}

.makeStyles-root-35.MuiBox-root.css-0 {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  margin: 40px 0px 20px 0px;
}

.makeStyles-header-281 {
  font-size: 16px;
  font-weight: bold;
  color: #374c6c;
}

.MuiTypography-root.MuiTypography-body1.css-1h7rvw2-MuiTypography-root {
  font-weight: bold;
}

.css-xwxb6v-MuiFormLabel-root-MuiInputLabel-root,
.css-198q2mz-MuiFormLabel-root-MuiInputLabel-root {
  font-weight: bold !important;
}

.css-1rmxosc-MuiButtonBase-root-MuiMenuItem-root {
  font-family: 'DM Sans' !important;
}

.MuiTypography-root.MuiTypography-body1.css-wi7g23-MuiTypography-root {
  margin: 16px 0px;
}

.MuiButtonBase-root.css-clrfx4-MuiButtonBase-root-MuiButton-root {
  margin-left: unset;
}

.css-1eodzux-MuiPaper-root-MuiCard-root {
  box-shadow: 0px 4px rgb(17 38 146 / 5%) !important;
}

.css-18cwzhq-MuiTypography-root {
  color: #374c6c !important;
}

#email-address {
  font-family: 'DM Sans' !important;
}

.agent_change_password h3 {
  font-size: 24px;
}

.css-1yo68gl-MuiInputBase-root-MuiOutlinedInput-root,
.css-158paoz {
  font-family: 'DM Sans' !important;
}

.css-i4bv87-MuiSvgIcon-root {
  color: #868c93;
}

.carouselItem {
  max-width: 240px;
  min-width: 240px;
  margin-right: 24px;
}
