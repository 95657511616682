body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

*:focus-visible {
  outline: none;
}

#bemoved-web {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.carousel-container {
  width: 100%;
  padding-bottom: 30px;

  .custom-dots {
    cursor: pointer;
  }

  .custom-arrow {
    position: absolute;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.right {
      right: 0;
    }
    &.left {
      margin-top: 10;
      left: 0;
    }
    &.left img {
      transform: rotate(-180deg);
    }
  }
}

.react-multi-carousel-dot-list {
  align-items: center;

  .active,
  .inactive {
    display: flex;
    align-items: center;
    margin-right: 5px;
  }
}
