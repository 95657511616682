
.internal-server {
  margin: 0;
  padding: 0;
  font-family: Arial, verdana, tahoma;
}

.internal-server-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ecf0f1;
}

.internal-server .container {
  text-align: center;
  margin: auto;
  padding: 4em;
}

.internal-server .container img {
  width: 256px;
  height: 225px;
}
.internal-server .container h1 {
  margin-top: 1rem;
  font-size: 35px;
  text-align: center;
}
.internal-server .container h1 span {
  font-size: 60px;
}
.internal-server .container p {
  margin-top: 1rem;
}
.internal-server .container p.info {
  margin-top: 4em;
  font-size: 12px;
}
.internal-server .container p.info a {
  text-decoration: none;
  color: #5454ce;
}